<script>
export default {
  name: "UpdateDeviationDialog",
  props: {
    isVisible: Boolean,
    agentId: [Number, String],
    channel: String,
    unitValue: String,
    value: Number,
    valuePercentage: Number,
    type: String,
  },
  data() {
    return {
      unit: 'digit',
      deviation: 0,
      deviation_percentage: 0,
      errors: {},
      isCreating: false
    }
  },
  watch: {
    isVisible(newValue) {
      if(newValue) {
        this.unit = this.unitValue ?? 'digit'
        this.deviation = this.value ?? 0
        this.deviation_percentage = this.valuePercentage ?? 0
      }
    }
  },
  methods: {
    async updateDeviation() {
      this.isCreating = true
      try {
        await this.$axios.post(`admin/message-dependent/deviation`, {
          agent_id: this.agentId,
          channel: this.channel,
          type: this.type,
          ...(this.unitValue === 'digit' && { deviation: this.deviation }),
          ...(this.unitValue === 'percentage' && { deviation_percentage: this.deviation_percentage }),
        })
        this.$emit('refresh')
        this.closeDialog()
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        this.isCreating = false
      }
    },
    closeDialog() {
      this.unit = 'digit'
      this.deviation = 0
      this.deviation_percentage = 0
      this.$emit('update:isVisible', false)
    }
  }
}
</script>

<template>
  <b-modal :active="isVisible" :can-cancel="false" @close="$emit('update:isVisible', false)">
    <div class="has-background-white-ter p-3">
      <form @submit.prevent="">
        <div class="columns is-flex-wrap-wrap">
          <div class="column is-half">
            <b-field label="Допустимое отклонение, Гц" :type="!!errors.deviation ? 'is-danger' : ''" :message="errors.deviation && errors.deviation[0] || ''">
              <b-numberinput v-if="unit === 'digit'" v-model="deviation" size="is-small" min="0" step="0.01" min-step="0.01"></b-numberinput>
              <b-numberinput v-else v-model="deviation_percentage" size="is-small" min="0" step="1" min-step="1" max="100"></b-numberinput>
            </b-field>
          </div>
          <div class="column is-half">
            <b-field label="Отображение">
              <b-radio v-model="unit" class="mt-1" :name="`${type}-digit-update`" native-value="digit">
                {{ type === 'vel' ? 'мм/с' : 'м/с²' }}
              </b-radio>
              <b-radio v-model="unit" class="mt-1 ml-3" :name="`${type}-percentage-update`" native-value="percentage">
                %
              </b-radio>
            </b-field>
          </div>
          <div class="column is-full is-flex is-justify-content-end">
            <b-button
              label="Отмена"
              type="button"
              class="mr-3"
              :disabled="isCreating"
              @click="closeDialog"
            />
            <b-button
              :label="'Сохранить'"
              class="is-info"
              type="submit"
              :loading="isCreating"
              @click="updateDeviation"
            />
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<style scoped>

</style>
