<script>
export default {
  name: "MessageTable",
  props: {
    data: Array,
    loading: Boolean,
    type: String,
    messageType: String,
    deletable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    columns() {
      // const dynamicColumns = [
      //   { field: "coefficient", label: "Коэф. скорости", },
      //   ...(this.type !== 'env' ? [{ field: "deviation", label: "Допустимое отклонение частоты", }] : [])
      // ]
      return [
        ...(this.messageType !== 'general' ? [{ field: "name", label: "Сохранить как" }] : []),
        // ...((this.type !== 'env' || this.messageType !== 'dependent') ? [{ field: "channel", label: "Канал" },] : []),
        { field: "text", label: "Сохранить тренд как" },
        ...(this.messageType === 'dependent' ? [{ field: "coefficient", label: "Коэф. скорости" }] : []),
        ...(this.messageType === 'independent' ? [{ field: "start_freq", label: "Начальная частота, Гц", }, { field: "end_freq", label: "Конечная частота, Гц", }] : []),
        { field: "warning", label: `Предупреждение, ${this.type !== 'acc' ? 'мм/с' : 'м/с²'}`, },
        { field: "alarm", label: `Тревога, ${this.type !== 'acc' ? 'мм/с' : 'м/с²'}`, },
      ]
    }
  },
  methods: {
    confirmDelete(id) {
      this.$buefy.dialog.confirm({
        title: 'Удаление',
        message: 'Вы действительно хотите удалить?',
        confirmText: 'Удалить',
        cancelText: 'Отмена',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.$emit('delete', id)
      })
    }
  }
}
</script>

<template>
  <b-table
    :data="data"
    :loading="loading"
    hoverable
    striped
    bordered
    narrowed
    class="clickable-table"
    @click="$emit('rowClick', $event)"
  >
    <b-table-column
      v-for="column in columns"
      :key="column.field"
      :field="column.field"
      :label="column.label"
      v-slot="props"
    >
      <p :class="[column.field === 'text' && 'is-ellipsis']" :title="props.row[column.field]">{{ props.row[column.field] }}</p>
    </b-table-column>
    <b-table-column
      v-if="deletable"
      :label="'Действия'"
      centered
      v-slot="props"
    >
      <b-button
        outlined
        size="is-small"
        class="is-danger"
        icon-right="trash"
        @click.stop.prevent="confirmDelete(props.row.id)"
      />
    </b-table-column>
    <template #empty>
      <div class="has-text-centered">Нет данных</div>
    </template>
  </b-table>
</template>

<style lang="scss">
.clickable-table {
  tr {
    cursor: pointer;
  }
}
</style>
