<script>
import MessageTable from "@/components/CMS/CMSDetail/MessageTable.vue"
import MessageModalCreateOrUpdate from "@/components/CMS/CMSDetail/MessageModalCreateOrUpdate.vue"

export default {
  name: "EnvTable",
  components: { MessageModalCreateOrUpdate, MessageTable },
  props: {
    agentId: [Number, String],
    messageType: String,
    loadData: Boolean,
    title: String,
    type: String,
  },
  data() {
    return {
      messages: [],
      editableItem: {},
      isLoading: false,
      dialogIsVisible: false,
      channels: [],
      selectedChannel: null
    }
  },
  watch: {
    loadData(newValue) {
      if(newValue && this.messageType && this.type) this.getMessages()
    }
  },
  methods: {
    async getMessages() {
      this.isLoading = true
      try {
        const { data } = await this.$axios.get(`admin/agents/${this.agentId}/message-${this.messageType}`, { params: { type: this.type } })
        this.channels = Object.keys(data?.grouped_by_channel ?? {})
        if(!this.selectedChannel) this.selectedChannel = Object.keys(data?.grouped_by_channel ?? {}).at(0)
        this.messages = data?.grouped_by_channel || []
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        this.isLoading = false
      }
    },
    async createMessage() {
      this.errors = {}
      this.isCreating = true
      try {
        if(!this.isEdit) await this.$axios.post(`admin/message-${this.messageType}`, { ...this.form, type: this.type, agent_id: this.agentId })
        else await this.$axios.put(`admin/message-${this.messageType}/` + this.message.id, { ...this.form, type: this.type, agent_id: this.agentId })
        this.$emit('refresh')
        this.closeDialog()
      } catch (e) {
        console.error(e)
        this.errors = e.error?.response?.data?.errors ?? {}
        throw e
      } finally {
        this.isCreating = false
      }
    },
    async deleteMessage(id) {
      try {
        await this.$axios.delete(`admin/message-${this.messageType}/` + id)
        await this.getMessages()
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    onRowClick(event) {
      this.editableItem = event
      this.dialogIsVisible = true
    }
  }
}
</script>

<template>
  <div class="mb-6">
    <div class="is-flex is-justify-content-space-between is-align-items-center mb-4">
      <span class="has-text-weight-semibold is-size-5">{{ title }}</span>
      <div class="is-flex is-align-items-center">
        <b-button
          label="Добавить"
          class="mr-2"
          type="is-info is-small"
          @click="dialogIsVisible = true"
        />
        <b-field v-if="channels.length > 0" label="Канал:" label-position="on-border" style="width: 200px">
          <!--              <b-input v-model="form.channel" />-->
          <b-select v-model="selectedChannel" expanded>
            <option
              v-for="option in channels"
              :key="option"
            >
              {{ option }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>
    <MessageTable :type="type" :message-type="messageType" :data="messages[selectedChannel]" :loading="isLoading" :deletable="false" @rowClick="onRowClick" @delete="deleteMessage" />
    <MessageModalCreateOrUpdate
      :agent-id="agentId"
      :channel="selectedChannel"
      :channels="channels"
      :message.sync="editableItem"
      :type="type"
      :message-type="messageType"
      :is-visible.sync="dialogIsVisible"
      @refresh="getMessages"
    />
  </div>
</template>

<style scoped>

</style>
