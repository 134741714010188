<script>
const FORM_INITIAL = {
  channel: '',
  bearing_id: 0,
  deviation: 0
}
export default {
  name: "SelectTypeDialog",
  props: {
    isVisible: Boolean,
    agentId: [Number, String],
    channels: Array,
    env: Object,
  },
  data() {
    return {
      form: { ...FORM_INITIAL },
      errors: {},
      types: [],
      typesCount: 0,
      page: 1,
      count: 10,
      search: '',
      isCreating: false,
      isLoading: false,
      isLastPage: false
    }
  },
  computed: {
    isEdit() {
      if(!this.env) return false
      return Object.keys(this.env).length > 0
    }
  },
  watch: {
    env(newValue) {
      if(newValue) Object.assign(this.form, newValue)
    },
    isVisible(newValue) {
      if(newValue) this.getTypes()
    }
  },
  methods: {
    async getTypes() {
      if(this.isLastPage) return

      this.isLoading = true
      const offset = (this.page * this.count) - this.count

      try {
        const { data } = await this.$axios.get(`admin/bearings`, {
          params: {
            search: this.search,
            count: this.count,
            offset,
          }
        })
        this.types = [...this.types, ...(data.data || [])]
        this.typesCount = data.count ?? 0
        this.isLastPage = this.types.length >= this.typesCount
        this.page++
      } catch (e) {
        throw new Error(e)
      } finally {
        this.isLoading = false
      }
    },
    onTypeSearch(query) {
      this.form.bearing_id = 0

      if(this.search !== query) {
        this.search = query
        this.types = []
        this.typesCount = 0
        this.page = 1
        this.isLastPage = false
        return this.getTypes()
      }

      if(!query && this.types.length) {
        this.types = []
        this.typesCount = 0
        this.page = 1
      }
    },
    async generateEnvs() {
      this.errors = {}
      this.isCreating = true
      try {
        await this.$axios.post(`admin/message-env-generate`, { ...this.form, agent_id: this.agentId })
        this.$emit('refresh')
        this.closeDialog()
      } catch (e) {
        this.errors = e.error?.response?.data?.errors ?? {}
        console.error(e)
        throw e
      } finally {
        this.isCreating = false
      }
    },
    async updateEnv() {
      if(!this.env.id) return
      try {
        await this.$axios.post('admin/update-deviation', {
          agent_id: this.agentId,
          bearing_id: this.env.id,
          deviation: this.form.deviation
        })
        this.$emit('refresh')
        this.closeDialog()
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    closeDialog() {
      Object.assign(this.form, { ...FORM_INITIAL })
      this.errors = {}
      this.page = 1
      this.types = []
      this.typesCount = 0
      this.search = ''
      this.$emit('update:isVisible', false)
      this.$emit('update:type', {})
      this.$emit('update:env', undefined)
    }
  }
}
</script>

<template>
  <b-modal :active="isVisible" :can-cancel="false" @close="$emit('update:isVisible', false)">
    <div class="has-background-white-ter p-5">
      <form @submit.prevent="">
        <div class="columns is-flex-wrap-wrap">
          <div v-if="!isEdit" class="column is-half">
            <b-field label="Канал" :type="!!errors.channel ? 'is-danger' : ''" :message="errors.channel && errors.channel[0] || ''">
              <!--              <b-input v-model="form.channel" />-->
              <b-select v-model="form.channel" expanded>
                <option
                  v-for="option in channels"
                  :key="option"
                >
                  {{ option }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div v-if="!isEdit" class="column is-half">
            <b-field label="Типы подшипников" :type="!!errors.bearing_id ? 'is-danger' : ''" :message="errors.bearing_id && errors.bearing_id[0] || ''">
              <!--              <b-input v-model="form.channel" />-->
<!--              <b-select v-model="form.bearing_id" expanded>-->
<!--                <option-->
<!--                  v-for="option in types"-->
<!--                  :value="option.id"-->
<!--                  :key="option.id"-->
<!--                >-->
<!--                  {{ option.manufacturer }} - {{ option.mark }}-->
<!--                </option>-->
<!--              </b-select>-->
              <b-autocomplete
                :data="types"
                placeholder=""
                append-to-body
                :check-infinite-scroll="true"
                open-on-focus
                :custom-formatter="option => `${option.manufacturer}, ${option.mark}`"
                @typing="onTypeSearch"
                @select="option => form.bearing_id = option.id"
                @infinite-scroll="getTypes(search)"
              >
                <template slot-scope="props">
                  <p class="is-selected">{{ props.option.manufacturer }}, {{ props.option.mark }}</p>
                </template>
              </b-autocomplete>
            </b-field>
          </div>
          <div class="column is-full">
            <b-field label="Допустимое отклонение, Гц" :type="!!errors.deviation ? 'is-danger' : ''" :message="errors.deviation && errors.deviation[0] || ''">
              <b-numberinput v-model="form.deviation" size="is-small" min="0" step="0.01" min-step="0.01"></b-numberinput>
            </b-field>
          </div>
          <div class="column is-full is-flex is-justify-content-end">
            <b-button
              label="Отмена"
              type="button"
              class="mr-3"
              :disabled="isCreating"
              @click="closeDialog"
            />
            <b-button
              :label="'Сохранить'"
              class="is-info"
              type="submit"
              :loading="isCreating"
              @click="isEdit ? updateEnv() : generateEnvs()"
            />
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<style scoped>

</style>
