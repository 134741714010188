<script>
import MessageList from "@/components/CMS/CMSDetail/MessageList.vue"

export default {
  name: "AccEdit",
  components: { MessageList },
  props: {
    agentId: [Number, String],
    channels: Array
  },
  data() {
    return {
      selectedChannel: ''
    }
  },
  watch: {
    channels(newValue) {
      if(newValue && newValue[0]) this.selectedChannel = newValue[0]
    }
  },
  methods: {
    onRowClick(event) {
      this.editableItem = event
    }
  }
}
</script>

<template>
  <div class="has-background-white p-5">
    <b-field v-if="channels.length > 0" label="Канал:" style="width: 200px">
      <!--              <b-input v-model="form.channel" />-->
      <b-select v-model="selectedChannel" expanded>
        <option
          v-for="option in channels"
          :key="option"
        >
          {{ option }}
        </option>
      </b-select>
    </b-field>
    <MessageList :key="`acc-ind-${agentId}`" :agent-id="agentId" :channel="selectedChannel" :channels="channels" title="Независящие от скорости границы" type="acc" message-type="independent" />
    <MessageList :key="`acc-dep-${agentId}`" :agent-id="agentId" :channel="selectedChannel" :channels="channels" title="Зависящие от скорости границы" type="acc" message-type="dependent" />
    <MessageList :key="`acc-gen-${agentId}`" :agent-id="agentId" :channel="selectedChannel" :channels="channels" title="Общие пороговые ограничения" type="acc" message-type="general" />
  </div>
</template>

<style scoped>

</style>
