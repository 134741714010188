<script>
import MessageTable from "@/components/CMS/CMSDetail/MessageTable.vue"
import MessageModalCreateOrUpdate from "@/components/CMS/CMSDetail/MessageModalCreateOrUpdate.vue"
import UpdateDeviationDialog from "@/components/CMS/Sub-component/UpdateDeviationDialog.vue"

export default {
  name: "MessageList",
  components: { UpdateDeviationDialog, MessageModalCreateOrUpdate, MessageTable },
  props: {
    agentId: [Number, String],
    messageType: String,
    channels: Array,
    title: String,
    type: String,
    channel: String
  },
  data() {
    return {
      unit: 'digit',
      deviationList: {},
      deviationPercentageList: {},
      deviationEditable: 0,
      deviationPercentageEditable: 0,
      messages: {},
      editableItem: {},
      isLoading: false,
      dialogIsVisible: false,
      deviationDialogIsVisible: false
    }
  },
  computed: {
    data() {
      return this.channel ? this.messages[this.channel] : []
    },
    deviationValue() {
      return this.unit === 'digit' ? (this.deviationList[this.channel] ?? 0) : (this.deviationPercentageList[this.channel] ?? 0)
    }
  },
  mounted() {
    if(this.messageType && this.type) this.getMessages()
  },
  methods: {
    async getMessages() {
      this.isLoading = true
      try {
        const { data } = await this.$axios.get(`admin/agents/${this.agentId}/message-${this.messageType}`, { params: { type: this.type } })
        this.messages = data?.grouped_by_channel || {}
        this.deviationList = data?.deviation ?? {}
        this.deviationPercentageList = data?.deviation_percentage ?? {}
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        this.isLoading = false
      }
    },
    async deleteMessage(id) {
      try {
        await this.$axios.delete(`admin/message-${this.messageType}/` + id)
        await this.getMessages()
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    onRowClick(event) {
      this.editableItem = event
      this.dialogIsVisible = true
    },
    onDeviationClick() {
      this.deviationDialogIsVisible = true
      this.deviationEditable = this.deviationList[this.channel] ?? 0
      this.deviationPercentageEditable = this.deviationPercentageList[this.channel] ?? 0
    }
  }
}
</script>

<template>
  <div class="mb-6">
    <template v-if="messageType === 'dependent'">
      <UpdateDeviationDialog
        :is-visible.sync="deviationDialogIsVisible"
        :agent-id="agentId"
        :channel="channel"
        :type="type"
        :unit-value="unit"
        :value="deviationEditable"
        :value-percentage="deviationPercentageEditable"
        @refresh="getMessages"
      />
      <div class="is-flex is-align-items-center mb-3">
        <table class="table is-bordered is-striped is-narrow mb-1">
          <tbody>
          <tr class="has-text-weight-semibold">
            <td class="pr-6">Допустимое отклонение, Гц</td>
            <td class="px-6" style="text-align: center; vertical-align: middle; cursor: pointer" @click="onDeviationClick">{{ deviationValue }}</td>
          </tr>
          </tbody>
        </table>
        <div class="ml-3">
          <b-radio v-model="unit" :name="`${type}-digit`" native-value="digit">
            {{ type === 'vel' ? 'мм/с' : 'м/с²' }}
          </b-radio>
          <b-radio v-model="unit" :name="`${type}-percentage`" class="ml-3" native-value="percentage">
            %
          </b-radio>
        </div>
      </div>
    </template>
    <div class="is-flex is-justify-content-space-between is-align-items-center mb-4">
      <span class="has-text-weight-semibold is-size-5">{{ title }}</span>
      <b-button
        class="is-info"
        :label="'+ Добавить'"
        size="is-small"
        @click="dialogIsVisible = true"
      />
    </div>
    <MessageTable :type="type" :message-type="messageType" :data="data" :loading="isLoading" @rowClick="onRowClick" @delete="deleteMessage" />
    <MessageModalCreateOrUpdate :agent-id="agentId" :channel="channel" :channels="channels" :message.sync="editableItem" :type="type" :message-type="messageType" :is-visible.sync="dialogIsVisible" @refresh="getMessages" />
  </div>
</template>

<style scoped>

</style>
