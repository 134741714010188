<script>
export default {
  name: "SelectCMS",
  props: {
    isVisible: Boolean,
    agentId: [Number, String],
  },
  data() {
    return {
      selectedItem: undefined,
      errors: {},
      items: [],
      itemsCount: 0,
      page: 1,
      count: 15,
      isCopying: false,
      isLoading: false,
      isLastPage: false
    }
  },
  watch: {
    isVisible(newValue) {
      if(newValue) this.getCMSList()
    }
  },
  methods: {
    async getCMSList() {
      if(this.isLastPage) return

      this.isLoading = true
      const offset = (this.page * this.count) - this.count

      try {
        const { data } = await this.$axios.get(`admin/agents/paginate/${offset}/${this.count}`)
        this.items = [...this.items, ...(data.data || [])]
        this.itemsCount = data.count ?? 0
        this.isLastPage = this.items.length >= this.itemsCount
        this.page++
      } catch (e) {
        throw new Error(e)
      } finally {
        this.isLoading = false
      }
    },
    async copyConfig() {
      this.isCopying = true
      try {
        await this.$axios.post('admin/copy-config', {
          agent_id: this.agentId,
          copy_agent_id: this.selectedItem
        })
        this.$emit('refresh')
      } catch (e) {
        this.errors = e.error?.response?.data?.errors ?? {}
        console.error(e)
        throw e
      } finally {
        this.isCopying = false
      }
    },
    closeDialog() {
      this.errors = {}
      this.page = 1
      this.items = []
      this.itemsCount = 0
      this.selectedItem = undefined
      this.$emit('update:isVisible', false)
    }
  }
}
</script>

<template>
  <b-modal :active="isVisible" :can-cancel="false" @close="$emit('update:isVisible', false)">
    <div class="has-background-white-ter p-5">
      <form @submit.prevent="">
        <div class="columns is-flex-wrap-wrap">
          <div class="column is-full">
            <b-field label="Выберите CMS" :type="!!errors.copy_agent_id ? 'is-danger' : ''" :message="errors.copy_agent_id && errors.copy_agent_id[0] || ''">
              <!--              <b-input v-model="form.channel" />-->
              <!--              <b-select v-model="form.bearing_id" expanded>-->
              <!--                <option-->
              <!--                  v-for="option in types"-->
              <!--                  :value="option.id"-->
              <!--                  :key="option.id"-->
              <!--                >-->
              <!--                  {{ option.manufacturer }} - {{ option.mark }}-->
              <!--                </option>-->
              <!--              </b-select>-->
              <b-autocomplete
                :data="items"
                placeholder=""
                append-to-body
                :check-infinite-scroll="true"
                open-on-focus
                :custom-formatter="option => `${option.device_name}`"
                @select="option => selectedItem = option.id"
                @infinite-scroll="getCMSList"
                @focus="$event.target.blur()"
              >
                <template slot-scope="props">
                  <p class="is-selected">{{ props.option.device_name }}</p>
                </template>
              </b-autocomplete>
            </b-field>
          </div>
          <div class="column is-full is-flex is-justify-content-end">
            <b-button
              label="Отмена"
              type="button"
              class="mr-3"
              :disabled="isCopying"
              @click="closeDialog"
            />
            <b-button
              :label="'Применить'"
              class="is-info"
              type="submit"
              :loading="isCopying"
              @click="copyConfig"
            />
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<style scoped>

</style>
