<script>
const FORM_INITIAL = {
  channel: '',
  text: '',
  coefficient: 0,
  start_freq: 0,
  end_freq: 0,
  warning: 0,
  alarm: 0,
}
export default {
  name: "MessageModalCreateOrUpdate",
  props: {
    isVisible: Boolean,
    agentId: [Number, String],
    channels: Array,
    channel: String,
    message: Object,
    type: String,
    messageType: String
  },
  data() {
    return {
      form: { ...FORM_INITIAL },
      errors: {},
      isCreating: false
    }
  },
  computed: {
    isEdit() {
      if(!this.message) return false
      return Object.keys(this.message).length
    },
    // isValid() {
    //   console.log(Object.values(this.form).filter(item => typeof item === 'number'))
    //   return Object.values(this.form).filter(item => typeof item === 'number').every(value => value > 0)
    // }
  },
  watch: {
    message(newValue) {
      if(newValue) Object.assign(this.form, newValue)
    }
  },
  methods: {
    async createMessage() {
      this.errors = {}
      this.isCreating = true
      try {
        if(!this.isEdit) await this.$axios.post(`admin/message-${this.messageType}`, { ...this.form, type: this.type, channel: this.channel, agent_id: this.agentId })
        else await this.$axios.put(`admin/message-${this.messageType}/` + this.message.id, { ...this.form, type: this.type, channel: this.channel, agent_id: this.agentId })
        this.$emit('refresh')
        this.closeDialog()
      } catch (e) {
        console.error(e)
        this.errors = e.error?.response?.data?.errors ?? {}
        throw e
      } finally {
        this.isCreating = false
      }
    },
    closeDialog() {
      Object.assign(this.form, { ...FORM_INITIAL })
      this.form.name = ''
      this.$emit('update:isVisible', false)
      this.$emit('update:message', {})
    }
  }
}
</script>

<template>
  <b-modal :active="isVisible" :can-cancel="false" @close="$emit('update:isVisible', false)">
    <div class="has-background-white-ter p-3">
      <form @submit.prevent="">
        <div class="columns is-flex-wrap-wrap">
          <div v-if="messageType !== 'general'" class="column is-full">
            <b-field label="Сохранить как" :type="!!errors.name ? 'is-danger' : ''" :message="errors.name && errors.name[0] || ''">
              <b-input v-model="form.name"/>
            </b-field>
          </div>
<!--          <div v-if="messageType !== 'dependent' || type !== 'env'" class="column is-full">-->
<!--            <b-field label="Канал" :type="!!errors.channel ? 'is-danger' : ''" :message="errors.channel && errors.channel[0] || ''">-->
<!--&lt;!&ndash;              <b-input v-model="form.channel" />&ndash;&gt;-->
<!--              <b-select v-model="form.channel" expanded>-->
<!--                <option-->
<!--                  v-for="option in channels"-->
<!--                  :key="option"-->
<!--                >-->
<!--                  {{ option }}-->
<!--                </option>-->
<!--              </b-select>-->
<!--            </b-field>-->
<!--          </div>-->
          <div class="column is-full">
            <b-field label="Название тренда" :type="!!errors.text ? 'is-danger' : ''" :message="errors.text && errors.text[0] || ''">
              <b-input v-model="form.text" type="textarea"/>
            </b-field>
          </div>
          <template v-if="messageType === 'independent'">
            <div class="column is-half">
              <b-field label="Начальная частота, Гц" :type="!!errors.start_freq ? 'is-danger' : ''" :message="errors.start_freq && errors.start_freq[0] || ''">
                <b-numberinput v-model="form.start_freq" size="is-small" min="0" step="0.01" min-step="0.01"></b-numberinput>
              </b-field>
            </div>
            <div class="column is-half">
              <b-field label="Конечная частота, Гц" :type="!!errors.end_freq ? 'is-danger' : ''" :message="errors.end_freq && errors.end_freq[0] || ''">
                <b-numberinput v-model="form.end_freq" size="is-small" min="0" step="0.01" min-step="0.01"></b-numberinput>
              </b-field>
            </div>
          </template>
          <template v-if="messageType === 'dependent'">
            <div class="column is-full">
              <b-field label="Коэф. скорости" :type="!!errors.coefficient ? 'is-danger' : ''" :message="errors.coefficient && errors.coefficient[0] || ''">
                <b-numberinput v-model="form.coefficient" size="is-small" min="0" step="0.00001" min-step="0.00001"></b-numberinput>
              </b-field>
            </div>
<!--            <div class="column is-half">-->
<!--              <b-field label="Допустимое отклонение, Гц" :type="!!errors.deviation ? 'is-danger' : ''" :message="errors.deviation && errors.deviation[0] || ''">-->
<!--                <b-numberinput v-model="form.deviation" size="is-small" min="0" step="0.01" min-step="0.01"></b-numberinput>-->
<!--              </b-field>-->
<!--            </div>-->
          </template>
          <div class="column is-half">
            <b-field label="Предупреждение" :type="!!errors.warning ? 'is-danger' : ''" :message="errors.warning && errors.warning[0] || ''">
              <b-numberinput v-model="form.warning" size="is-small" min="0" step="0.01" min-step="0.01"></b-numberinput>
            </b-field>
          </div>
          <div class="column is-half">
            <b-field label="Тревога" :type="!!errors.alarm ? 'is-danger' : ''" :message="errors.alarm && errors.alarm[0] || ''">
              <b-numberinput v-model="form.alarm" size="is-small" min="0" step="0.01" min-step="0.01"></b-numberinput>
            </b-field>
          </div>
          <div class="column is-full is-flex is-justify-content-end">
            <b-button
              label="Отмена"
              type="button"
              class="mr-3"
              :disabled="isCreating"
              @click="closeDialog"
            />
            <b-button
              :label="isEdit ? 'Сохранить' : 'Добавить'"
              class="is-info"
              type="submit"
              :loading="isCreating"
              @click="createMessage"
            />
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<style scoped>

</style>
